import React, { useState } from "react";

import uspImage from "../../assets/appstore/usp1.jpeg";
import anhanImage from "../../assets/appstore/anahan1.jpeg";
import campImage from "../../assets/appstore/unicamp1.jpeg";

import jornalUsp from "../../assets/usp/jornalusp.png";

export default function Intro() {
  const [currentImage, setCurrentImage] = useState(null);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

  const handleMouseEnter = (event, image) => {
    setMousePosition({ x: event.clientX, y: event.clientY });
    setCurrentImage(image);
  };

  const handleMouseMove = (event) => {
    setMousePosition({ x: event.clientX, y: event.clientY });
  };

  const handleMouseLeave = () => {
    setCurrentImage(null);
  };

  return (
    <div className="bg-gray-100 dark:bg-gray-800 text-gray-900 dark:text-gray-100 mt-10">
      <div className="px-4 py-6 sm:px-6 md:px-10 lg:px-14 text-justify">
        <h2 className="text-xl sm:text-2xl font-bold mb-4 text-center py-6 sm:py-10 dark:text-white">
          Vamos impulsionar o UniFOA no universo Tech!
        </h2>
        <p className="text-base sm:text-lg mb-4 dark:text-white">
          Conheça aqui as primeiras propostas da revolução Tech que irá elevar o
          nível da tecnologia dentro das universidades.
        </p>

        <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 sm:gap-8">
          <div>
            <p className="text-base sm:text-lg mb-4 dark:text-white">
              Essa proposta foi criada com muito carinho e dedicação pelo
              desenvolvedor{" "}
              <a
                href="https://www.linkedin.com/in/jadercardoso"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-500 hover:text-blue-700 dark:text-blue-300 dark:hover:text-blue-500"
                title="Ir para LinkedIn"
              >
                Jader Cardoso.
              </a>{" "}
              {/* com auxílio da Desenvolvedora{" "}
              <a
                href="https://www.linkedin.com/in/sarahcarlasouza"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-500 hover:text-blue-700 dark:text-blue-300 dark:hover:text-blue-500"
                title="Ir para LinkedIn"
              >
                Sarah Souza
              </a>. */}
            </p>
            <p className="text-base sm:text-lg mb-4 dark:text-white">
              Universidades como a{" "}
              <span
                className="text-base sm:text-lg text-blue-500 cursor-pointer dark:text-blue-300"
                onMouseEnter={(e) => handleMouseEnter(e, uspImage)}
                onMouseMove={handleMouseMove}
                onMouseLeave={handleMouseLeave}
              >
                USP
              </span>
              ,{" "}
              <span
                className="text-base sm:text-lg text-blue-500 cursor-pointer dark:text-blue-300"
                onMouseEnter={(e) => handleMouseEnter(e, anhanImage)}
                onMouseMove={handleMouseMove}
                onMouseLeave={handleMouseLeave}
              >
                Anhanguera
              </span>{" "}
              e{" "}
              <span
                className="text-base sm:text-lg text-blue-500 cursor-pointer dark:text-blue-300"
                onMouseEnter={(e) => handleMouseEnter(e, campImage)}
                onMouseMove={handleMouseMove}
                onMouseLeave={handleMouseLeave}
              >
                Unicamp
              </span>{" "}
              já possuem uma série de aplicativos que agregam valor às suas marcas.
              O UniFOA também tem o potencial de se tornar uma referência em
              tecnologia, e a criação de aplicações é um passo fundamental para
              demonstrar a força e o alcance da marca UniFOA.
            </p>
            <p className="text-base sm:text-lg mb-4 dark:text-white">
              Essa proposta é apresentada com o intuito de elevar o UniFOA a um novo
              patamar tecnológico, aproveitando ao máximo as capacidades dos
              envolvidos e fortalecendo a posição da universidade no cenário
              educacional e tecnológico.
            </p>
            <p className="text-base sm:text-lg mb-4 dark:text-white">
              Nossa maior inspiração é a USP, que desenvolve vários aplicativos e é a
              maior referência que temos no Brasil.
            </p>
          </div>
          <div className="flex justify-center">
            <img
              src={jornalUsp}
              className="w-full max-w-xs sm:max-w-md lg:max-w-xl rounded-lg"
              alt="Jornal USP"
            />
          </div>
        </div>

        {currentImage && (
          <div
            className="absolute bg-white border rounded-lg shadow-lg dark:bg-gray-800"
            style={{
              top: mousePosition.y + 10,
              left: mousePosition.x + 10,
              maxWidth: '100%',
              maxHeight: '100%',
            }}
          >
            <img src={currentImage} alt="University" className="w-64 h-auto" />
          </div>
        )}
      </div>
    </div>
  );
}
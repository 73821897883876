import React, { useState, useEffect } from 'react';

const ClubeMaisAppCarousel = ({ images }) => {
  const [currentImage, setCurrentImage] = useState(0);

  const handleNext = () => {
    setCurrentImage((prevImage) => (prevImage + 1) % images.length);
  };

  const handlePrev = () => {
    setCurrentImage((prevImage) => (prevImage - 1 + images.length) % images.length);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      handleNext();
    }, 3000); // Passa para a próxima imagem a cada 3 segundos

    return () => clearInterval(interval); // Limpa o intervalo ao desmontar o componente
  }, [currentImage]);

  return (
    <div className="relative w-full max-w-[300px] h-[700px] mx-auto">
      <img
        src={images[currentImage]}
        alt={`Mobile Image ${currentImage + 1}`}
        className="w-full h-full object-cover"
      />
      <div className="absolute top-1/2 left-0 w-full flex justify-between items-center">
        <button
          className="bg-gray-700 text-white p-2 rounded-full"
          onClick={handlePrev}
        >
          &#8249;
        </button>
        <button
          className="bg-gray-700 text-white p-2 rounded-full"
          onClick={handleNext}
        >
          &#8250;
        </button>
      </div>
    </div>
  );
};

export default ClubeMaisAppCarousel;

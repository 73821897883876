import React from "react";
import Banner from "../Banner";
import tcc from "../../assets/images/tcc.jpg";

export default function TccBanner() {
  return (
    <Banner imageUrl={tcc} title="TCC UniFOA" id="tcc">
      <div className="mx-10 my-10">
      <h2 className="text-2xl font-bold mb-4">Guia TCC UniFOA</h2>
      <p className="text-base md:text-lg mb-4 text-justify">
        O Guia TCC UniFOA é um aplicativo desenvolvido para ser um recurso
        essencial para os alunos durante a produção do TCC. Este aplicativo
        fornece uma visão detalhada dos padrões exigidos, além de um guia
        completo de formatação, com o objetivo de simplificar o processo e
        permitir que os alunos se concentrem mais no conteúdo do trabalho e
        menos nas regras de formatação.
      </p>
      <p className="text-base md:text-lg mb-4 text-justify">
        Atualmente, o UniFOA disponibiliza um guia em PDF com 77 páginas, cuja
        última atualização é de 2009. Este documento, apesar de ser uma
        referência importante, é difícil de localizar e navegar devido ao seu
        tamanho e formato desatualizado. O nosso aplicativo visa resolver essas
        questões, proporcionando uma experiência mais acessível e prática para
        os alunos.
        <ul className="list-disc pl-5 mt-2 mb-5">
              <p className="font-bold mt-10">Disponibilidade:</p>
              <li className="ml-10">App Android</li>
              <li className="ml-10">App iOS</li>
            </ul>
            <p className="mx-2 mt-10 mb-2 font-extrabold">Prazo de desenvolvimento: 2 - 4 Meses.</p>
      </p>
      </div>
    </Banner>
  );
}

import React, { useState } from "react";
import image1 from '../../assets/apps/policlinica/1.png';
import image2 from '../../assets/apps/policlinica/2.png';
import image3 from '../../assets/apps/policlinica/3.png';

const images = [image1, image2, image3];

export default function PoliclinicaCarousel() {
  const [currentImage, setCurrentImage] = useState(0);

  const handleNext = () => {
    setCurrentImage((prevImage) => (prevImage + 1) % images.length);
  };

  const handlePrev = () => {
    setCurrentImage((prevImage) => (prevImage - 1 + images.length) % images.length);
  };

  return (
    <div className="relative w-full h-[60vh] md:h-[80vh]">
      <img
        src={images[currentImage]}
        alt={`Slide ${currentImage + 1}`}
        className="w-full h-full object-contain rounded-lg"
      />
      <button
        onClick={handlePrev}
        className="absolute left-0 top-1/2 transform -translate-y-1/2 bg-black bg-opacity-50 text-white p-2 rounded-full"
      >
        &#8249;
      </button>
      <button
        onClick={handleNext}
        className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-black bg-opacity-50 text-white p-2 rounded-full"
      >
        &#8250;
      </button>
    </div>
  );
}

import React, { useState, useEffect } from 'react';
import img1 from '../../assets/apps/linkedFoa/1.png';
import img2 from '../../assets/apps/linkedFoa/2.png';
import img3 from '../../assets/apps/linkedFoa/3.png';
import img4 from '../../assets/apps/linkedFoa/4.png';
import img5 from '../../assets/apps/linkedFoa/5.png';
import img6 from '../../assets/apps/linkedFoa/6.png';

const images = [img1, img2, img3, img4, img5, img6];

export default function LinkedFoaCarousel() {
    const [currentIndex, setCurrentIndex] = useState(0);

    const goToPrevious = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === 0 ? images.length - 1 : prevIndex - 1
        );
    };
    const goToNext = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === images.length - 1 ? 0 : prevIndex + 1
        );
    };

    useEffect(() => {
        const intervalId = setInterval(goToNext, 3000); 
        return () => clearInterval(intervalId);
    }, []);

    return (
        <div className="relative w-full max-w-md mx-auto overflow-hidden">
            <button
                className="absolute top-1/2 left-2 transform -translate-y-1/2 bg-gray-800 text-white p-2 rounded-full shadow-lg focus:outline-none"
                onClick={goToPrevious}
            >
                &lt;
            </button>
            <div className="overflow-hidden w-full h-[66%] flex items-center">
            <button
                className="absolute top-1/2 left-2 transform -translate-y-1/2 bg-gray-800 text-white p-2 rounded-full shadow-lg focus:outline-none"
                onClick={goToPrevious}
            >
                &lt;
            </button>
                <div
                    className="flex transition-transform duration-500 ease-in-out"
                    style={{ transform: `translateX(-${currentIndex * 100}%)` }}
                >
                    {images.map((src, index) => (
                        <div key={index} className="flex-shrink-0 w-full h-full flex justify-center items-center">
                            <img
                                src={src}
                                alt={`Slide ${index + 1}`}
                                className="max-w-[66%] max-h-[66%] object-cover"
                            />
                        </div>
                    ))}
                </div>
            </div>
            <button
                className="absolute top-1/2 right-2 transform -translate-y-1/2 bg-gray-800 text-white p-2 rounded-full shadow-lg focus:outline-none"
                onClick={goToNext}
            >
                &gt;
            </button>
        </div>
    );
};
import React, { useState,useEffect} from "react";
import Banner from "../Banner";
import events from "../../assets/images/unifoaevents.jpg";

import imagem1 from "../../assets/apps/unifoaEvents/1.png";
import imagem2 from "../../assets/apps/unifoaEvents/2.png";
import imagem3 from "../../assets/apps/unifoaEvents/3.png";

const images = [imagem1, imagem2, imagem3];

export default function EventsBanner() {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000); // Muda a imagem a cada 3 segundos

    return () => clearInterval(interval); // Limpa o intervalo quando o componente é desmontado
  }, []);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
  };

  return (
    <Banner imageUrl={events} title="UniFOA Events" id="events">
      <div className="mx-10 my-10 flex flex-col md:flex-row justify-between items-start text-justify">
        <div className="flex-1 mb-6 md:mb-0">
          <h2 className="text-2xl font-bold mb-4">UniFOA Events</h2>
          <p className="text-base md:text-lg mb-4">
            O <strong>UniFOA Events</strong> é um aplicativo desenvolvido para manter toda a comunidade UniFOA informada sobre os eventos mais importantes que acontecem no UniFOA.
          </p>
          <p className="text-base md:text-lg mb-4">
            Com o UniFOA Events, os alunos terão acesso a todas as informações de eventos que acontecem no UniFOA, incluindo atividades e eventos das atléticas, eventos institucionais e muito mais.
          </p>
          <p className="text-base md:text-lg mb-4">
            Este aplicativo é a sua solução para ficar por dentro dos eventos, como portas abertas, palestras e outros eventos de maneira fácil, organizada e acessível, tudo na palma da mão.
          </p>
          <ul className="list-disc pl-5 mt-2 mb-5">
            <p className="font-bold mt-10">Disponibilidade:</p>
            <li className="ml-10">App Android</li>
            <li className="ml-10">App iOS</li>
            <li className="ml-10">Gerenciador Web</li>
          </ul>
          <p className="mx-2 mt-10 mb-2 font-extrabold">Prazo de desenvolvimento: 5 - 6 Meses.</p>
        </div>
        <div className="flex-1 max-w-full">
          {/* Carrossel */}
          <div className="relative h-[700px] overflow-hidden">
            <img
              src={images[currentIndex]}
              alt={`Slide ${currentIndex + 1}`}
              className="w-full h-full object-contain rounded-lg shadow-md transition-transform duration-300"
            />
            <button
              onClick={handlePrev}
              className="absolute top-1/2 left-2 transform -translate-y-1/2 bg-gray-700 text-white p-2 rounded-full focus:outline-none"
            >
              &lt;
            </button>
            <button
              onClick={handleNext}
              className="absolute top-1/2 right-2 transform -translate-y-1/2 bg-gray-700 text-white p-2 rounded-full focus:outline-none"
            >
              &gt;
            </button>
          </div>
        </div>
      </div>
    </Banner>
  );
}

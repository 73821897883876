import React from "react";
import Banner from "../Banner";
import blablafoa from '../../assets/images/blablafoa.jpg';
import BlaBlaFoaCarousel from "./BlaBlaFoaCarousel";

export default function BlaBlaFoaBanner() {
  return (
    <div>
      <Banner
        imageUrl={blablafoa}
        title="BlaBlaFOA"
        id="blablafoa"
      >

      
      
      <div className="mt-8 mx-10 px-10 mb-10 text-justify">
        <h2 className="text-2xl font-bold mb-4">BlaBlaFOA /UniFOA Mobility</h2>
        <p className="text-lg mb-4">
        O BlaBlaFOA, também conhecido como UniFOA Mobility, surge da ideia de facilitar a conexão entre alunos que dirigem e aqueles que dependem de transporte público, 
        promovendo o compartilhamento de caronas dentro da comunidade acadêmica.
        O aplicativo reunirá motoristas que estão indo ou voltando para o UniFOA e os conectará com alunos que precisam de transporte.
        Além disso, é uma excelente ferramente de networking entre os alunos, os possibilitando conversar com alunos de outros períodos, outros cursos e outras áreas.

        </p>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div className="md:col-span-1">
            <BlaBlaFoaCarousel />
          </div>
          <div className="md:col-span-2 text-lg mb-4 py-6">
          <p className=" text-lg mb-4 py-2">
            Essa plataforma não apenas incentiva a colaboração e o espírito comunitário entre os estudantes, 
            mas também contribui para a redução de custos de transporte e o impacto ambiental, ao diminuir a quantidade de veículos em circulação. 
            O BlaBlaFOA oferece uma solução prática e segura para que os alunos possam se deslocar de forma mais eficiente e sustentável.
            </p>
            <p className="font-bold text-lg mb-4 py-2">
              Haverá também a funcionalidade de exibir os horários do Transporte Social UniFOA.
            </p>
            <p className="text-lg mb-4  py-6">
              Esta plataforma foi criada com o objetivo de fortalecer a comunidade acadêmica, facilitando a interação e colaboração entre os alunos. 
              Se você está procurando por alguém para dividir uma carona, formar um grupo de estudo ou apenas compartilhar ideias, o BlaBlaFOA é o lugar certo.
            </p>
            <p className="text-lg mb-4">
              Acreditamos que a conexão entre estudantes é essencial para o crescimento acadêmico e pessoal. 
            </p>

            <ul className="list-disc pl-5 mt-2">
              <p className="font-bold mt-10">Disponibilidade:</p>
              <li className="ml-10">App Android</li>
              <li className="ml-10">App iOS</li>
            </ul>
            <p className="mx-2 mt-10 mb-2 font-extrabold">Prazo de desenvolvimento: 4 - 6 Meses.</p>
          </div>
        </div>
      </div>
      </Banner>
    </div>
  );
}
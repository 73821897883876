import React, { useState, useEffect, useRef } from "react";
import { FaMoon, FaSun, FaChevronDown, FaChevronUp } from "react-icons/fa";
import jc from "../../assets/images/JC.png";
import { Link } from "react-router-dom";

function HeaderFoa() {
  const [darkMode, setDarkMode] = useState(
    localStorage.getItem("darkMode") === "true"
  );
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [hasScrolled, setHasScrolled] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    if (darkMode) {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
    localStorage.setItem("darkMode", darkMode);
  }, [darkMode]);

  // Verifica quando o usuário rola a página
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setHasScrolled(true);
      } else {
        setHasScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const toggleDarkMode = () => {
    setDarkMode((prevMode) => !prevMode);
  };

  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
      setIsDropdownOpen(false);
    }
  };

  const toggleDropdown = () => {
    setIsDropdownOpen((prev) => !prev);
  };

  return (
    <header
      className={`fixed top-0 w-full transition-all duration-300 z-50 bg-white dark:bg-gray-900 text-gray-900 dark:text-gray-100 ${
        hasScrolled ? "shadow-lg h-16" : "h-20"
      } `}
    >
      <div className="flex justify-between items-center">
        <Link to="/">
          <img
            src={jc}
            className={`w-20 transition-all duration-300 ${
              darkMode ? "filter invert" : ""
            }`}
            alt="Logo"
          />
        </Link>
        <nav>
          <ul className="flex space-x-4">
            <li className="relative" ref={dropdownRef}>
              <Link to="/foa">
                <button className="flex items-center space-x-2 text-blue-600 dark:text-blue-400 hover:underline">
                  <span>Aplicativos</span>
                  {isDropdownOpen ? (
                    <FaChevronUp
                      className="transition-transform duration-300"
                      onClick={toggleDropdown}
                    />
                  ) : (
                    <FaChevronDown
                      className="transition-transform duration-300"
                      onClick={toggleDropdown}
                    />
                  )}
                </button>
              </Link>
              <ul
                className={`absolute left-0 mt-2 w-48 bg-white dark:bg-gray-800 text-gray-900 dark:text-gray-100 border border-gray-300 dark:border-gray-700 shadow-lg transition-all duration-300 ease-out ${
                  isDropdownOpen
                    ? "opacity-100 translate-y-0 pointer-events-auto"
                    : "opacity-0 -translate-y-2 pointer-events-none"
                }`}
                style={{ transformOrigin: "top center" }}
              >
                {/* Dropdown items */}
                <li>
                  <button
                    onClick={() => scrollToSection("unifoagym")}
                    className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-700 w-full text-left"
                  >
                    UniFOA GYM
                  </button>
                </li>
                <li>
                  <button
                    onClick={() => scrollToSection("clube-mais")}
                    className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-700 w-full text-left"
                  >
                    Clube+ UniFOA
                  </button>
                </li>
                <li>
                  <button
                    onClick={() => scrollToSection("books")}
                    className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-700 w-full text-left"
                  >
                    UniFOA BOOKS
                  </button>
                </li>
                <li>
                  <button
                    onClick={() => scrollToSection("blablafoa")}
                    className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-700 w-full text-left"
                  >
                    BlaBlaFOA
                  </button>
                </li>
                <li>
                  <button
                    onClick={() => scrollToSection("linkedfoa")}
                    className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-700 w-full text-left"
                  >
                    LinkedFOA
                  </button>
                </li>
                <li>
                  <button
                    onClick={() => scrollToSection("events")}
                    className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-700 w-full text-left"
                  >
                    UniFOA Events
                  </button>
                </li>
                <li>
                  <button
                    onClick={() => scrollToSection("tcc")}
                    className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-700 w-full text-left"
                  >
                    TCC UniFOA
                  </button>
                </li>
                <li>
                  <button
                    onClick={() => scrollToSection("policlinica")}
                    className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-700 w-full text-left"
                  >
                    PoliclinicAPP
                  </button>
                </li>
              </ul>
            </li>
          </ul>
        </nav>
        <div className="flex space-x-4">
          {/* Novo Link para /foa/project */}
          <Link
            to="/foa/project"
            className="text-blue-600 dark:text-blue-400 hover:underline"
          >
            Acompanhar desenvolvimento
          </Link>
        </div>
        <div className="flex space-x-4">
          {/* Novo Link para /foa/project */}
          <Link
            to="/foa/sac"
            className="text-blue-600 dark:text-blue-400 hover:underline"
          >
            SAC
          </Link>
        </div>
        <button
          onClick={toggleDarkMode}
          className="px-4 py-2 rounded bg-transparent border border-gray-300 dark:border-gray-700 flex items-center justify-center"
        >
          {darkMode ? (
            <FaSun className="text-yellow-500 dark:text-yellow-300" size={24} />
          ) : (
            <FaMoon className="text-gray-900 dark:text-gray-100" size={24} />
          )}
        </button>
      </div>
    </header>
  );
}

export default HeaderFoa;

import React, { useState, useEffect } from "react";
import Banner from "../Banner";
import books from "../../assets/images/books.jpg";
import BooksCarousel from "./BooksCarousel";
import dash1 from "../../assets/apps/unifoaBooks/dash1.png";
import dash2 from "../../assets/apps/unifoaBooks/dash2.png";

export default function BooksBanner() {
  const [currentImage, setCurrentImage] = useState(0);
  const images = [dash1, dash2];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImage((prevImage) => (prevImage + 1) % images.length);
    }, 3000);

    return () => clearInterval(interval);
  }, [images.length]);

  return (
    <div>
      <Banner imageUrl={books} title="UniFOA BOOKS." id="books">
        <div className="mt-8">
          <h2 className="text-2xl font-bold mb-4 mx-10 px-10">
            UniFOA BOOKS / Espaço do Estudante UniFOA
          </h2>
          <p className="text-lg mb-4 pr-10 mr-10 ml-20 text-justify">
            A ideia inicial do UniFOA BOOKS surgiu como um marketplace dedicado
            à venda e doação de livros (novos ou usados), onde os próprios
            alunos poderiam anunciar os livros que não utilizam mais. No
            entanto, essa proposta foi ampliada para incluir um marketplace mais
            abrangente, voltado para atender diversas necessidades dos
            estudantes.
          </p>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="md:col-span-1">
              <BooksCarousel />
              <div className="pl-10 ml-10">
                <ul className="list-disc pl-10 ml-10 mt-2">
                  <p className="font-bold mt-10">Disponibilidade:</p>
                  <li className="ml-10">App Android</li>
                  <li className="ml-10">App iOS</li>
                  <li className="ml-10">Gerenciador Web</li>
                </ul>
                <p className="mx-2 mt-10 mb-2 font-extrabold">
                  Prazo de desenvolvimento: 4 - 6 Meses.
                </p>
              </div>
            </div>
            <div className="md:col-span-2">
              <p className="mt-10 pr-10 mr-10 text-lg text-justify mb-4">
                Além de livros, os alunos poderão vender uma variedade de
                produtos, como doces, materiais escolares, e outros itens. O
                espaço também incluirá o módulo #partiu, uma área destinada à
                venda de móveis, aparelhos eletrônicos e outros materiais por
                alunos que estão de mudança. Essa funcionalidade é especialmente
                útil para estudantes que se mudaram para a região de Volta
                Redonda para estudar e desejam vender seus pertences para outros
                alunos que estão em busca de itens para compra.
              </p>
              <p className="mt-10 pr-10 mr-10 text-justify text-lg">
                Essa plataforma não só facilita o acesso a materiais e produtos
                entre os alunos, mas também promove a economia colaborativa
                dentro da comunidade acadêmica, criando um espaço dinâmico e de
                suporte mútuo.
              </p>
              <div className="relative w-full h-96 mt-8">
                <img
                  src={images[currentImage]}
                  alt={`Screenshot ${currentImage + 1}`}
                  className="w-full h-full object-contain"
                />
                <div className="absolute top-0 left-0 w-full h-full flex justify-between items-center"></div>
                <p className="px-4 py-4 text-lg text-justify">Haverá também o gerenciador da biblioteca, que anunciará quais livros estão disponíveis para doação.</p>
              </div>
            </div>
          </div>
        </div>
      </Banner>
    </div>
  );
}
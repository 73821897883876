import React from "react";
import Banner from "../Banner";
import clubemais from "../../assets/images/clubemais.jpg";

import img1 from "../../assets/apps/clubemais/1.png";
import img2 from "../../assets/apps/clubemais/2.png";
import img3 from "../../assets/apps/clubemais/3.png";
import img4 from "../../assets/apps/clubemais/4.png";
import img5 from "../../assets/apps/clubemais/5.png";

import dash1 from "../../assets/apps/clubemais/dash1.png";
import dash2 from "../../assets/apps/clubemais/dash2.png";
import dash3 from "../../assets/apps/clubemais/dash3.png";
import dash4 from "../../assets/apps/clubemais/dash4.png";
import dash5 from "../../assets/apps/clubemais/dash5.png";

import ClubeMaisDashCarousel from "./ClubeMaisDashCarousel";
import ClubeMaisAppCarousel from "./ClubeMaisAppCarousel";

export default function ClubeMaisBanner() {
  const mobileImgs = [img1, img2, img3, img4, img5];
  const dashImages = [dash1, dash2, dash3, dash4, dash5];

  return (
    <div>
      <Banner imageUrl={clubemais} title="Clube+ UniFOA" id="clube-mais">
        <div className="mt-8 mx-10">
          <h2 className="text-2xl font-bold mb-4 mx-10">Clube+ UniFOA</h2>
          <p className="text-lg mb-4 ml-6 text-justify">
            O aplicativo Clube+ UniFOA pode ser desenvolvido internamente,
            personalizado pela equipe de Relacionamento e Serviços.
          </p>

          <div className="flex flex-col md:flex-row items-start">
            {/* Texto ao lado do Carrossel de Imagens Móveis */}
            <div className="md:w-1/2 p-6">
              <p className="text-lg mb-4 text-justify">
                Atualmente, o aplicativo é contratado e fornecido pela empresa
                Alloyal, o que gera um custo mensal para o UniFOA, havendo,
                ainda, limite de vidas e a impossibilidade de uso por egressos e
                funcionários do H.FOA.
              </p>
              <p className="text-lg mb-4 text-justify">
                Com o desenvolvimento do aplicativo sendo feito in house, esse
                custo mensal seria praticamente eliminado. Além da redução de
                custos, o desenvolvimento interno permite uma maior
                personalização e alinhamento com as necessidades específicas do
                UniFOA, garantindo que o aplicativo esteja sempre atualizado e
                em sintonia com as demandas da universidade e de seus usuários.
              </p>
              <p className="text-lg mb-4 text-justify">
                Cabe destacar que o app criado pela Alloyal exige que
                funcionários administrativos assinem um termo de
                compartilhamento de dados em respeito à LGPD, pois seus dados
                são compartilhados com uma empresa terceira. Sendo o app Clube+
                UniFOA desenvolvido e gerido internamente pelo UniFOA,
                eliminaria a necessidade do aceite dos termos.
              </p>

              <h3 className="text-xl font-bold mb-4">Cadastro de empresas:</h3>
              <p className="text-lg mb-4 text-justify">
                Totalmente operado pela Divisão de Relacionamento e serviços,
                permitindo o cadastro, remoção e edição em menos de 5 minutos.
                Na plataforma da lecupon, ocorre que estas ações levam dias.
              </p>
              <p className="text-lg mb-4 text-justify">
                Facilidade de visualizar os dados da empresa, assim como dados
                dos responsáveis, não necessitando a procura e consulta a
                documentos armazenados na rede.
              </p>

              <h3 className="text-xl font-bold mb-4">Promoções</h3>
              <p className="text-lg mb-4 text-justify">
                Facilidade e agilidade para cadastrar novas promoções, editá-las
                e excluir. Permite também a criação de promoções relâmpago.
              </p>

              <h3 className="text-xl font-bold mb-4">Sorteios</h3>
              <p className="text-lg mb-4 text-justify">
                Possibilidade da criação de sorteios pela Loteria Federal.
              </p>

              <ul className="list-disc pl-5 mt-2">
                <p className="font-bold mt-10">Disponibilidade:</p>
                <li className="ml-10">App Android</li>
                <li className="ml-10">App iOS</li>
              </ul>
              <p className="mx-2 mt-10 mb-2 font-extrabold">
                Prazo de desenvolvimento: 3 - 4 Meses.
              </p>
            </div>

            {/* Carrossel de Imagens Móveis */}
            <div className="md:w-1/2">
              <ClubeMaisAppCarousel images={mobileImgs} />
            </div>
          </div>

          {/* Carrossel de Imagens de Desktop */}
          <div className="mt-8">
            <ClubeMaisDashCarousel images={dashImages} />
          </div>
        </div>
      </Banner>
    </div>
  );
}

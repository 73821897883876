import React from "react";
import Banner from "../Banner";
import policlinica from '../../assets/images/policlinicaodonto.jpg';
import PoliclinicaCarousel from "./PoliclinicaCarousel";
import dashboard from '../../assets/apps/policlinica/dashboard.png';

export default function PoliclinicaBanner() {
  return (
    <div>
      <Banner
        imageUrl={policlinica}
        title="App Agendamento Policlinica+Odonto"
        id="policlinica"
      >
        <div className="mt-8 mx-10 px-10 mb-10 text-justify">
          <h2 className="text-2xl font-bold mb-4">Aplicação Web para Agendamento Policlínica + Odontologia</h2>
          <p className="text-lg mb-4">
            Atualmente, os agendamentos tanto na Policlínica quanto na Odontologia do UniFOA são realizados via WhatsApp, utilizando serviços pagos que cobram por mensagem (360 dialog + SZ.Chat). 
            Esse processo, além de custoso, exige que os membros da comunidade FOA entrem em contato durante horários específicos para efetuar seus agendamentos.
          </p>
          
          

          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="md:col-span-1">
              <PoliclinicaCarousel />
            </div>
            <div className="md:col-span-2 text-lg mb-4 py-6">
            <p className="text-lg mb-4">
            A proposta é desenvolver uma aplicação web simples, porém eficaz, que permitirá aos usuários agendar seus atendimentos diretamente. 
            Um link integrado ao WhatsApp UniFOA (via chatbot SZ.Chat) direcionará o usuário para uma interface onde ele poderá escolher o horário de seu agendamento.
          </p>
            <p className="text-lg mb-4">
            Essa solução não só facilita o processo de agendamento para os usuários, mas também oferece à Policlínica e à Clínica Odontológica do UniFOA a capacidade de gerenciar os pedidos de agendamento de maneira mais eficiente. 
            Com essa ferramenta, a universidade poderá reduzir os custos associados ao atendimento via WhatsApp e melhorar a experiência do usuário.
          </p>
              <p className="text-lg mb-4 py-2 pt-5">
                A plataforma foi desenvolvida para oferecer uma interface intuitiva e de fácil uso, garantindo que os pacientes possam marcar suas consultas de forma rápida e segura. 
                Com o aplicativo, é possível visualizar os horários disponíveis, escolher a especialidade desejada e confirmar o agendamento em poucos cliques.
              </p>
              <p className="text-lg mb-4 py-2 font-bold pt-10">
                O aplicativo também oferece suporte para o agendamento de múltiplas consultas em diferentes especialidades e permite o acompanhamento dos agendamentos futuros.
              </p>
             
            </div>
            
          </div>
          <p className="text-lg mb-4 pt-10">
                Além disso, o App Agendamento Policlinica+Odonto conta com notificações automáticas para lembrar os pacientes de suas consultas, e a possibilidade de reagendar ou cancelar diretamente pelo aplicativo.
              </p>
          <div className="mt-10 flex justify-center">
            
            <img
              src={dashboard}
              alt="Dashboard do aplicativo"
              className="max-w-full"
              style={{ maxWidth: '800px', maxHeight: '600px' }}
            />
            <div>
              <ul className="list-disc pl-5 mt-2">
              <p className="font-bold mt-10">Disponibilidade:</p>
              <li className="ml-10">Aplicação Web</li>
            </ul>
            <p className="mx-2 mt-10 mb-2 font-extrabold">Prazo de desenvolvimento: 2 - 4 Meses.</p>
            </div>
          </div>
        </div>
        
      </Banner>
    </div>
  );
}

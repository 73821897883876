import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaMoon, FaSun, FaChevronDown, FaChevronUp } from 'react-icons/fa';
import jc from '../assets/images/JC.png';

function Header() {
  const [darkMode, setDarkMode] = useState(
    localStorage.getItem('darkMode') === 'true'
  );
  const [scrolled, setScrolled] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [consultoriaOpen, setConsultoriaOpen] = useState(false);

  const navigate = useNavigate();
  const menuRef = useRef(null);

  useEffect(() => {
    if (darkMode) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
    localStorage.setItem('darkMode', darkMode);
  }, [darkMode]);

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 0);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuRef]);

  const toggleDarkMode = () => {
    setDarkMode((prevMode) => !prevMode);
  };

  const handleLogoClick = () => {
    navigate('/');
  };

  const toggleMenu = () => {
    setMenuOpen((prevState) => !prevState);
  };

  const toggleConsultoria = () => {
    setConsultoriaOpen((prevState) => !prevState);
  };

  const handleNavigate = (path) => {
    navigate(path);
    setMenuOpen(false); // Fechar o menu ao navegar
  };

  return (
    <header
      className={`fixed top-0 left-0 right-0 p-4 flex bg-white dark:bg-gray-900 text-gray-900 dark:text-gray-100 justify-between items-center transition-all duration-300 ${
        scrolled ? 'bg-opacity-90 shadow-xl' : 'bg-opacity-100 shadow-none'
      }`}
      style={{ height: '60px' }}
    >
      <img
        src={jc}
        className={`w-20 transition-all duration-300 ${darkMode ? 'filter invert' : ''}`}
        alt="Logo"
        onClick={handleLogoClick}
        style={{ cursor: 'pointer' }}
      />
      <div className="relative flex-1 flex justify-center" ref={menuRef}>
        <button
          onClick={toggleMenu}
          className="px-4 py-2 text-gray-900 dark:text-gray-100 bg-transparent dark:border-gray-700 rounded flex items-center"
        >
          Menu
          {menuOpen ? (
            <FaChevronUp className="ml-2" />
          ) : (
            <FaChevronDown className="ml-2" />
          )}
        </button>
        {menuOpen && (
          <div className="absolute top-full mt-2 w-[32rem] bg-white dark:bg-gray-800 shadow-lg rounded-lg transform -translate-x-1/2 left-1/2">
            <ul className="py-2">
              <li
                className=" py-2 text-center hover:bg-gray-200 dark:hover:bg-gray-700 cursor-pointer"
                // onClick={() => handleNavigate('/projects')}
                style={{ cursor: 'not-allowed'}}
              >
                Projetos
              </li>
              <li
                className="px-4 py-2 text-center hover:bg-gray-200 dark:hover:bg-gray-700 cursor-pointer flex justify-center items-center"
                onClick={toggleConsultoria}
              >
                Consultoria Tech
                {consultoriaOpen ? (
                  <FaChevronUp className="ml-2" />
                ) : (
                  <FaChevronDown className="ml-2" />
                )}
              </li>
              {consultoriaOpen && (
                <ul>
                  <li
                    className=" py-2 text-center hover:bg-gray-200 dark:hover:bg-gray-700 cursor-pointer"
                    // onClick={() => handleNavigate('/consultoriatech')}
                    style={{ cursor: 'not-allowed' }}
                  >
                    Para Escritórios de Advocacia
                  </li>
                  <li
                    className="px-4 py-2 text-center hover:bg-gray-200 dark:hover:bg-gray-700 cursor-pointer"
                    // onClick={() => handleNavigate('/consultoriatech')}
                    style={{ cursor: 'not-allowed' }}
                  >
                    Para Oficinas
                  </li>
                  <li
                    className="px-4 py-2 text-center hover:bg-gray-200 dark:hover:bg-gray-700 cursor-pointer"
                    // onClick={() => handleNavigate('/consultoriatech')}
                    style={{ cursor: 'not-allowed' }}
                  >
                    Para Colégios e Universidades
                  </li>
                </ul>
              )}
              <li
                className="px-4 py-2 text-center hover:bg-gray-200 dark:hover:bg-gray-700 cursor-pointer"
                // onClick={() => handleNavigate('/blog')}
                style={{ cursor: 'not-allowed' }}
              >
                Blog
              </li>
              <li
                className="px-4 py-2 text-center hover:bg-gray-200 dark:hover:bg-gray-700 cursor-pointer"
                // onClick={() => handleNavigate('/about')}
                style={{ cursor: 'not-allowed' }}
              >
                Sobre
              </li>
            </ul>
          </div>
        )}
      </div>
      <button
        onClick={toggleDarkMode}
        className="px-4 py-2 rounded bg-transparent border border-gray-300 dark:border-gray-700 flex items-center justify-center"
      >
        {darkMode ? (
          <FaSun className="text-yellow-500 dark:text-yellow-300" size={24} />
        ) : (
          <FaMoon className="text-gray-900 dark:text-gray-100" size={24} />
        )}
      </button>
    </header>
  );
}

export default Header;

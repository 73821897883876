import React, { useState, useRef, useEffect } from 'react';

function BannerLab({ imageUrl, title, children }) {
  const [isOpen, setIsOpen] = useState(false);
  const contentRef = useRef(null);

  const toggleInfo = () => {
    setIsOpen(prevState => !prevState);
  };

  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.style.maxHeight = isOpen ? `${contentRef.current.scrollHeight}px` : '0px';
    }
  }, [isOpen]);

  return (
    <div>
      <div className="relative cursor-pointer" onClick={toggleInfo}>
        <div className="relative bg-blue-600 h-[300px] shadow-md hover:shadow-lg transition-shadow duration-300">
          <img
            src={imageUrl}
            alt="LabFOA"
            className="h-2/3 object-contain absolute left-4 top-1/2 transform -translate-y-1/2"
          />
          <div className="absolute inset-0 flex items-center justify-center">
            <h1 className="text-white text-3xl font-bold hover:shadow-lg transition-shadow duration-300">
              {title}
            </h1>
          </div>
          <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2">
            <svg
              className={`w-6 h-6 text-white hover:text-gray-400 transition-colors duration-300 transform ${isOpen ? 'rotate-180' : ''}`}
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
            </svg>
          </div>
        </div>
        <div className="absolute inset-0 bg-gradient-to-t from-black to-transparent opacity-50 hover:opacity-70 transition-opacity duration-300"></div>
      </div>
      <div
        ref={contentRef}
        className={`overflow-hidden transition-all duration-500 ease-in-out bg-gray-100 dark:bg-gray-800 text-gray-900 dark:text-gray-100`}
      >
        {children}
      </div>
    </div>
  );
}

export default BannerLab;

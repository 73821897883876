import React, { useState, useEffect } from 'react';

const ClubeMaisDashCarousel = ({ images }) => {
  const [currentImage, setCurrentImage] = useState(0);

  const handleNext = () => {
    setCurrentImage((prevImage) => (prevImage + 1) % images.length);
  };

  const handlePrev = () => {
    setCurrentImage((prevImage) => (prevImage - 1 + images.length) % images.length);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      handleNext();
    }, 5000);

    return () => clearInterval(interval); 
  }, [currentImage]);

  return (
    <div className="relative w-full max-w-[70%] mx-auto h-auto">
      <img
        src={images[currentImage]}
        alt={`Desktop Image ${currentImage + 1}`}
        className="w-full h-auto object-cover"
      />
      <div className="absolute top-1/2 left-0 w-full flex justify-between items-center">
        <button
          className="bg-gray-700 text-white p-2 rounded-full"
          onClick={handlePrev}
        >
          &#8249;
        </button>
        <button
          className="bg-gray-700 text-white p-2 rounded-full"
          onClick={handleNext}
        >
          &#8250;
        </button>
      </div>
    </div>
  );
};

export default ClubeMaisDashCarousel;

import React from "react";
import Banner from "../Banner";
import linkedfoa from "../../assets/images/linkedfoa.jpg";
import LinkedFoaCarousel from "./LinkedFoaCarousel";

export default function LinkedFoaBanner() {
  return (
    <Banner imageUrl={linkedfoa} title="LinkedFOA" id="linkedfoa">
      <div className="my-10 ml-20 flex flex-col lg:flex-row lg:items-start">
        {/* Texto à esquerda */}
        <div className="flex-1 lg:w-2/3 pr-6">
          <h2 className="text-2xl font-bold mb-4">LinkedFOA</h2>
          <p className="text-lg mb-4 text-justify">
            O LinkedFOA será a nova rede social profissional institucional
            projetada para conectar estagiários e empresas de forma eficaz.
            Encontrar estágios pode ser um desafio, especialmente com a
            concorrência acirrada de outras universidades. No entanto, o
            LinkedFOA está aqui para facilitar esse processo para os alunos da
            UniFOA.
          </p>
          <p className="text-lg mb-4 text-justify">
            Com o LinkedFOA, os alunos terão acesso a uma plataforma intuitiva
            que simplifica a busca por estágios e oportunidades de emprego.
            Empresas e recrutadores poderão encontrar candidatos qualificados de
            maneira rápida e eficiente. Nossa missão é criar uma ponte entre
            talentos emergentes e oportunidades no mercado de trabalho, tornando
            o processo de recrutamento e busca de estágio mais ágil e acessível.
          </p>
          <ul className="list-disc pl-5 mt-2">
              <p className="font-bold mt-10">Disponibilidade:</p>
              <li className="ml-10">App Android</li>
              <li className="ml-10">App iOS</li>
            </ul>
            <p className="mx-2 mt-10 mb-2 font-extrabold">Prazo de desenvolvimento: 6 - 8 Meses.</p>
        </div>

        {/* Carrossel à direita */}
        <div className="flex-1 lg:w-1/3">
          <LinkedFoaCarousel />
        </div>
      </div>
    </Banner>
  );
}

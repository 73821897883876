import React, { useState, useEffect } from 'react';

export default function ScrollToTop() {
  const [isVisible, setIsVisible] = useState(false);
  const [showPopup, setShowPopup] = useState(true); 
  
  const handleScroll = () => {
    if (window.scrollY > 50) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    
    const popupTimeout = setTimeout(() => {
      setShowPopup(false);
    }, 5000);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      clearTimeout(popupTimeout); 
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div>
      {isVisible && (
        <button
          onClick={scrollToTop}
          className={`fixed bottom-4 right-4 p-3 bg-blue-600 text-white rounded-full shadow-lg z-50 transition-transform transform hover:scale-105 ${
            isVisible ? 'opacity-100' : 'opacity-0'
          }`}
          style={{ transition: 'opacity 0.3s ease' }}
          aria-label="Voltar ao início"
        >
          <svg
            className="w-6 h-6"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M19 15l-7-7-7 7"
            />
          </svg>
        </button>
      )}

      {showPopup && isVisible && (
        <div className="fixed bottom-16 right-4 bg-gray-800 text-white p-2 rounded-lg shadow-md z-50">
          Clique aqui para voltar para o topo da página
        </div>
      )}
    </div>
  );
}

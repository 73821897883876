import React from 'react';
import { Link } from 'react-router-dom'; // Importa o componente Link do react-router-dom
import jc from '../assets/images/JC.png';

export default function Footer() {
    return (
        <footer className="bg-white text-black py-4 dark:bg-gray-900 dark:text-gray-100">
            <div className="container mx-auto flex flex-col md:flex-row justify-between items-center px-4">
                {/* Coluna Esquerda */}
                <div className="flex-1 text-center md:text-left mb-4 md:mb-0 ">
                    <Link to="/"> {/* Adiciona o Link ao redor da imagem */}
                        <img 
                            src={jc} 
                            alt="JC Logo" 
                            className="mx-auto h-16 transition-colors duration-300 dark:filter dark:invert" 
                        />
                    </Link>
                </div>
                
                {/* Coluna Central */}
                <div className="flex-1 text-center mb-4 md:mb-0">
                    <p className="text-sm">&copy; 2024 Jader B. Cardoso. Todos os direitos reservados.</p>
                </div>
                
                {/* Coluna Direita */}
                <div className="flex-1 text-center md:text-right ">
                    <a className="block text-sm hover:underline mb-2" style={{ cursor: 'not-allowed' }}>Contato</a>
                    <a className="block text-sm hover:underline mb-2" style={{ cursor: 'not-allowed' }}>Consultoria</a>
                    <a className="block text-sm hover:underline mb-2" style={{ cursor: 'not-allowed' }}>Sobre</a>
                    <a className="block text-sm hover:underline" style={{ cursor: 'not-allowed' }}>Privacidade</a>
                </div>
            </div>
        </footer>
    );
}

import React, { useEffect } from 'react';
import BannerLab from '../BannerLab';
import labfoapng from '../../assets/images/labfoa.png';

import desenvolverusp from "../../assets/usp/desenvolverusp.png";
import justificativaUsp from "../../assets/usp/justificativaUsp.png";
import appsusp from "../../assets/usp/appsUsp.png";
import jornalusp from "../../assets/usp/jornalusp.png";
import proximosusp from "../../assets/usp/proximosusp.png";
import caronae from '../../assets/usp/caronae.png'

export default function LabFoaBanner() {
  useEffect(() => {
    let currentIndex = 0;
    const images = document.querySelectorAll('.carousel-image');
    const totalImages = images.length;

    const changeImage = () => {
      images[currentIndex].classList.add('hidden');
      currentIndex = (currentIndex + 1) % totalImages;
      images[currentIndex].classList.remove('hidden');
    };

    const interval = setInterval(changeImage, 6000);

    return () => clearInterval(interval); 
  }, []);

  return (
    <BannerLab
      imageUrl={labfoapng}
      title="LabFOA"
      id="labfoa"
    >  
      <div className="my-6 px-4 md:px-10 lg:px-20 flex flex-col lg:flex-row lg:items-start">
        <div className="flex-1 lg:w-2/3 pr-4 lg:pr-6 text-justify">
          <h2 className="text-xl sm:text-2xl font-bold mb-4">LabFOA</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 md:gap-8">
            <div>
              <p className="text-base md:text-lg mb-4 text-justify">
                Bem-vindo à revolução DEV do UniFOA!
              </p>
              <p className="text-base md:text-lg mb-4">
                O curso de Sistemas de Informação do UniFOA tem formado inúmeros
                talentos ao longo dos anos.
              </p>
              <p className="text-base md:text-lg mb-4">
                Desde estudantes que iniciaram o curso sem conhecimento prévio em
                programação até aqueles que se graduaram como profissionais altamente
                qualificados. Muitos desses alunos desenvolveram trabalhos de conclusão
                de curso (TCCs) notáveis, os quais alguns foram transformados em
                startups ou produtos reais.
              </p>
              <p className="text-base md:text-lg mb-4">
                O objetivo do LabFOA é adotar ideias de alunos e funcionários da Universidade com o objetivo de
                criar aplicativos, tanto para fins administrativos quanto acadêmicos, para o UniFOA. 
                Essa iniciativa visa destacar a capacidade dos alunos do curso de Sistemas de
                Informação em desenvolver soluções inovadoras que atendam às demandas do
                mercado de trabalho, além de fortalecer a área de informática da
                universidade.
              </p>
              <p className="text-base md:text-lg mb-4">
                O trabalho da equipe de informática da USP é referência nesta prática, pois
                possibilita a sugestão de criação de aplicações e soluções.
              </p>
            </div>
            <div className="relative w-full max-w-[800px] h-[600px] overflow-hidden rounded-lg  shadow-lg">
              <img src={desenvolverusp} className="carousel-image absolute inset-0 w-full h-full object-cover" alt="Desenvolver USP" />
              <img src={justificativaUsp} className="carousel-image absolute inset-0 w-full h-full object-cover hidden" alt="Justificativa USP" />
              <img src={appsusp} className="carousel-image absolute inset-0 w-full h-full object-cover hidden" alt="Apps USP" />
              <img src={jornalusp} className="carousel-image absolute inset-0 w-full h-full object-cover hidden" alt="Jornal USP" />
              <img src={proximosusp} className="carousel-image absolute inset-0 w-full h-full object-cover hidden" alt="Próximos USP" />
              <img src={caronae} className="carousel-image absolute inset-0 w-full h-full object-cover hidden" alt="App Caronae UFF" />
            </div>
          </div>
        </div>
      </div>
    </BannerLab>
  );
}

import Banner from "../Banner";
import saclabfoa from "../../assets/images/saclabfoa.jpg";

export default function SacBanner() {
    return (
        <Banner imageUrl={saclabfoa} title="SacLabFOA" id="saclabfoa">
            <div className="mt-8 mx-10 px-10 mb-10 text-justify">
                <h2 className="text-3xl font-extrabold mb-6">SAC LabFOA</h2>
                <p className="text-lg mb-6">
                    O SAC LabFOA é um sistema exclusivo desenvolvido para integrar todas as soluções oferecidas pelo Lab FOA. 
                    Ele conecta de forma eficiente os serviços de suporte, resolução de problemas e gestão de informações,
                    centralizando tudo em uma plataforma unificada.
                </p>
                <p className="text-lg mb-6">
                    Com uma interface independente, o sistema facilita o gerenciamento de aplicações e oferece um 
                    controle otimizado para as demandas do Lab FOA, garantindo mais autonomia para os projetos.
                </p>
                <p className="text-lg font-semibold mb-6">
                    Cada detalhe foi cuidadosamente pensado para oferecer eficiência e independência no gerenciamento dos projetos.
                </p>
                <p className="text-lg font-bold mt-8">
                    Prazo de desenvolvimento: 4 - 6 meses.
                </p>
            </div>
        </Banner>
    );
}

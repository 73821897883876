import React, { useEffect, useState } from 'react';
import HeaderFoa from '../components/FoaPage/HeaderFoa';

export default function LogsPage() {
  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchLogs = async () => {
      try {
        const response = await fetch('https://api-yr5nil2b4q-uc.a.run.app/logs');
        const data = await response.json();
        setLogs(data);
      } catch (error) {
        setError('Erro ao buscar logs');
      } finally {
        setLoading(false);
      }
    };
    fetchLogs();
  }, []);

  const formatDate = (date) => {
    // Verifica se o objeto de data possui as propriedades _seconds e _nanoseconds
    if (date && date._seconds && date._nanoseconds) {
      const newDate = new Date(date._seconds * 1000 + date._nanoseconds / 1000000);
      return `${newDate.getDate().toString().padStart(2, '0')}/${(newDate.getMonth() + 1).toString().padStart(2, '0')}/${newDate.getFullYear()} ${newDate.getHours().toString().padStart(2, '0')}:${newDate.getMinutes().toString().padStart(2, '0')}`;
    }
    return 'Data não disponível';
  };

  const calculateProgress = () => {
    if (logs.length === 0) return 0;
    const totalProgress = 50;
    return (totalProgress / (logs.length * 100)) * 100;
  };

  return (
    <>
      <HeaderFoa />
      <div className="bg-white dark:bg-gray-900 text-gray-900 dark:text-gray-100 min-h-screen flex flex-col items-center justify-center">
      <div className="container mx-auto pt-40">
        <h1 className="text-2xl font-bold mb-4">Progresso Atual</h1>

        {/* Barra de Progresso */}
        <div className="w-full bg-gray-200 rounded-full h-6 mb-4">
          <div
            className="bg-blue-600 h-6 rounded-full"
            style={{ width: `${calculateProgress()}%` }}
          />
        </div>

          {/* Campos para "Projeto Atual" e "Próximo Projeto" */}
          <div className="mb-8 text-center">
          <label className="block text-gray-700 text-lg font-semibold mb-2 dark:text-gray-100">Projeto Atual</label>
          <h2 className="text-xl font-bold text-blue-600">Desenvolvimento do sistema de SAC e LOG</h2>
          <h3 className="text-l font-semibold text-gray-800 dark:text-gray-100">Prazo estimado para conclusão: nov/24 - dez/24 </h3>

          <label className="block text-gray-700 text-lg font-semibold mt-4 mb-2 dark:text-gray-100">Próximo Projeto</label>
          <h2 className="text-xl font-bold text-blue-600">App Clube+ UniFOA</h2>
        </div>

        {/* Título da seção de logs */}
        <h2 className="text-2xl font-bold mb-4 text-gray-700 dark:text-gray-100">Histórico de Atualizações</h2>

        {/* Exibir logs */}
        {loading ? (
          <p>Carregando logs...</p>
        ) : error ? (
          <p>{error}</p>
        ) : (
            <ul className="bg-gray-100 rounded-lg pt-10 pb-3 px-10 shadow-md dark:bg-gray-700">
            {logs.slice().reverse().map((log) => ( 
              <li key={log.id} className="mb-6 border-b border-gray-300 pb-4">
                <p className="text-gray-700 dark:text-gray-100">
                  {log.date ? formatDate(log.date) : log.creationDate} : {log.description || log.details || 'Sem descrição'}
                </p>
              </li>
            ))}
          </ul>
        )}
      </div>
      </div>
      
    </>
  );
}

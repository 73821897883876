import React, { useState, useEffect } from "react";
import um from "../../assets/apps/unifoaGym/1.png";
import dois from "../../assets/apps/unifoaGym/2.png";
import tres from "../../assets/apps/unifoaGym/3.png";
import quatro from "../../assets/apps/unifoaGym/4.png";
import cinco from "../../assets/apps/unifoaGym/5.png";
import seis from "../../assets/apps/unifoaGym/6.png";
import sete from "../../assets/apps/unifoaGym/7.png";
import oito from "../../assets/apps/unifoaGym/8.png";

const images = [um, dois, tres, quatro, cinco, seis, sete, oito];

const GymCarousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  useEffect(() => {
    const intervalId = setInterval(nextSlide, 5000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="relative w-full max-w-[66%] h-[66%]">
      <div className="overflow-hidden w-full h-full">
        <div
          className="flex transition-transform duration-500"
          style={{ transform: `translateX(-${currentIndex * 100}%)` }}
        >
          {images.map((image, index) => (
            <div key={index} className="flex-shrink-0 w-full h-full">
              <img
                src={image}
                alt={`Slide ${index + 1}`}
                className="w-full h-full object-cover"
              />
            </div>
          ))}
        </div>
      </div>
      <button
        className="absolute top-1/2 left-1 transform -translate-y-1/2 bg-gray-800 text-white p-2 rounded-full"
        onClick={prevSlide}
      >
        &lt;
      </button>
      <button
        className="absolute top-1/2 right-1 transform -translate-y-1/2 bg-gray-800 text-white p-2 rounded-full"
        onClick={nextSlide}
      >
        &gt;
      </button>
    </div>
  );
};

export default GymCarousel;

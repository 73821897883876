import React, { useState, useRef, useEffect } from 'react';

function Banner({ imageUrl, title, children, id }) {
  const [isOpen, setIsOpen] = useState(false);
  const contentRef = useRef(null);

  const toggleInfo = () => {
    setIsOpen(prevState => !prevState);
  };

  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.style.height = isOpen ? `${contentRef.current.scrollHeight}px` : '0px';
    }
  }, [isOpen]);

  return (
    <div id={id} className="relative">
      <div className="relative" onClick={toggleInfo}>
        <img
          src={imageUrl}
          alt="Banner"
          className="w-full h-[300px] object-cover cursor-pointer"
        />
        <div className="absolute inset-0 flex items-center justify-center bg-gradient-to-t from-black to-transparent opacity-50 hover:opacity-70 transition-opacity duration-300">
          <h1 className="text-white text-3xl font-bold hover:shadow-lg transition-shadow duration-300">
            {title}
          </h1>
          <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2">
            <svg
              className={`w-6 h-6 text-white hover:text-gray-400 transition-colors duration-300 transform ${isOpen ? 'rotate-180' : ''}`}
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
            </svg>
          </div>
        </div>
      </div>
      <div
        ref={contentRef}
        className={`overflow-hidden transition-all duration-300 ease-out bg-gray-100 dark:bg-gray-800 text-gray-900 dark:text-gray-100`}
      >
        {children}
      </div>
    </div>
  );
}

export default Banner;

import React, { useEffect, useState } from 'react';

function PageLoader({ isLoading }) {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    let interval;

    if (isLoading) {
      setProgress(0);
      interval = setInterval(() => {
        setProgress(prev => {
          if (prev >= 100) {
            clearInterval(interval);
            return 100;
          }
          return prev + 1; 
        });
      }, 30);
    } else {
      setProgress(100); 
    }

    return () => clearInterval(interval);
  }, [isLoading]);

  return (
    <div className={`fixed inset-0 flex items-center justify-center bg-white dark:bg-gray-900 z-50 ${isLoading ? 'opacity-100' : 'opacity-0 pointer-events-none'} transition-opacity duration-500`}>
    <div className="w-full max-w-md mx-auto">
      <div className="relative pt-1">
        {/* <div className="flex mb-2 items-center justify-between">
          <div className="text-xs font-semibold inline-block py-1 px-2 rounded-full dark:text-blue-200 text-blue-700 dark:bg-blue-800 bg-blue-200 uppercase">
            Carregando...
          </div>
        </div> */}
        <div className="relative pt-1">
          <div className="flex">
            <div className="flex-1 text-xs font-semibold inline-block py-1 px-2 rounded-full dark:text-blue-200 text-blue-700 dark:bg-blue-800 bg-blue-200">
              <div className="bg-blue-700 dark:bg-blue-400 h-2 rounded-full" style={{ width: `${progress}%`, transition: 'width 0.3s ease' }}></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  );
}

export default PageLoader;

import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './pages/HomePage';
import FoaPage from './pages/FoaPage';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import FoaInfoPage from './pages/FoaInfoPage';
import Contato from './pages/contato';
import Sobre from './pages/Sobre';
import Consultoria from './pages/Consultoria';
import Projects from './pages/Projetos';
import LogsPage from './pages/LogsPage';
import SacPage from './pages/SacPage';
import MintsPage from './pages/Mints';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/foa" element={<FoaPage />} />
        <Route path="/foa/informacoes" element={<FoaInfoPage />}/>
        <Route path="/contato" element={<Contato />}/>
        <Route path="/about" element={<Sobre />}/>
        <Route path="/consultoriatech" element={<Consultoria />}/>
        <Route path="/projects" element={<Projects />}/>
        <Route path="/foa/project" element={<LogsPage />} />
        <Route path="/foa/sac" element={<SacPage />} />
        <Route path="/mints" element={<MintsPage />} />
      </Routes>
    </Router>
  );
}

export default App;
import React, { useEffect, useState } from "react";
import PageLoader from "../components/PageLoader";
import HeaderFoa from "../components/FoaPage/HeaderFoa";

export default function SacPage() {
  const [isLoading, setIsLoading] = useState(true);
  const [isContentVisible, setIsContentVisible] = useState(false);
  const [formData, setFormData] = useState({
    requester: "",
    subject: "",
    description: "",
    application: "",
    contact: "",
    requestType: "Suporte Técnico",
  });
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
      setIsContentVisible(true);
    }, 500);
    return () => clearTimeout(timer);
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage("");
    try {
      const response = await fetch("https://api-yr5nil2b4q-uc.a.run.app/sac", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setIsSubmitted(true);
      } else {
        setErrorMessage("Erro ao enviar SAC. Tente novamente.");
      }
    } catch (error) {
      setErrorMessage("Erro de conexão com a API.");
    }
  };

  return (
    <>
      <PageLoader isLoading={isLoading} />
      <div
        className={`transition-opacity duration-500 bg-white dark:bg-gray-900 text-gray-900 dark:text-gray-100 ${
          isContentVisible ? "opacity-100" : "opacity-0"
        } min-h-screen`} // Use `min-h-screen` para garantir que ocupe toda a altura da tela
      >
        <HeaderFoa />
        <div className="max-w-lg mx-auto mt-20 pt-20 p-4 pb-20 bg-white dark:bg-gray-900 text-gray-900 dark:text-gray-100 container ">
          {!isSubmitted ? (
            <form onSubmit={handleSubmit} className="space-y-4">
              <div>
                <label className="block text-sm font-medium">Nome:</label>
                <input
                  type="text"
                  name="requester"
                  value={formData.requester}
                  onChange={handleInputChange}
                  className="w-full border p-2 rounded-md dark:bg-gray-900"
                  required
                />
              </div>

              <div>
                <label className="block text-sm font-medium">Assunto:</label>
                <input
                  type="text"
                  name="subject"
                  value={formData.subject}
                  onChange={handleInputChange}
                  className="w-full border p-2 rounded-md dark:bg-gray-900"
                  required
                />
              </div>

              <div>
                <label className="block text-sm font-medium">Descrição:</label>
                <textarea
                  name="description"
                  value={formData.description}
                  onChange={handleInputChange}
                  className="w-full border p-2 rounded-md dark:bg-gray-900"
                  required
                ></textarea>
              </div>

              <div>
                <label className="block text-sm font-medium">Aplicativo:</label>
                <select
                  name="application"
                  value={formData.application}
                  onChange={handleInputChange}
                  className="w-full border p-2 rounded-md dark:bg-gray-900"
                  required
                >
                  <option value="">Selecione um aplicativo</option>
                  <option value="Clube+UniFOA">Clube+UniFOA</option>
                  <option value="UniFOA GYM">UniFOA GYM</option>
                </select>
              </div>

              <div>
                <label className="block text-sm font-medium">E-mail para contato:</label>
                <input
                  type="email"
                  name="contact"
                  value={formData.contact}
                  onChange={handleInputChange}
                  className="w-full border p-2 rounded-md dark:bg-gray-900"
                  required
                />
              </div>

              <div>
                <label className="block text-sm font-medium">Tipo de Solicitação:</label>
                <select
                  name="requestType"
                  value={formData.requestType}
                  onChange={handleInputChange}
                  className="w-full border p-2 rounded-md dark:bg-gray-900"
                >
                  <option value="Suporte Técnico">Suporte Técnico</option>
                  <option value="Dúvida">Dúvida</option>
                  <option value="Problema">Problema</option>
                </select>
              </div>

              {errorMessage && <p className="text-red-500">{errorMessage}</p>}

              <button
                type="submit"
                className="w-full bg-blue-600 text-white p-2 rounded-md hover:bg-blue-900"
              >
                Enviar SAC
              </button>
            </form>
          ) : (
            <div className="text-green-600 text-center ">
              <p>SAC enviado com sucesso! Em breve entraremos em contato &#128513;</p>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

import Header from "../components/Header";
import image1 from "../assets/images/mints/image1.jpg";
import image2 from "../assets/images/mints/image2.jpg";
// import image3 from "../assets/image3.jpg";
import image3 from "../assets/images/mints/image3.jpg";
import image4 from "../assets/images/mints/grafico.png";
import image5 from "../assets/images/mints/valuation.png";
import mints from "../assets/images/mints/mints.png";
import vtr from "../assets/images/mints/vtr.png";
import valores from "../assets/images/mints/valores.png"
import Footer from "../components/Footer";
import concorrentes from "../assets/images/mints/concorrentes.png"

export default function MintsPage() {
  return (
    <>
      <Header />
      <div className="bg-white dark:bg-gray-900 text-gray-900 dark:text-gray-100 min-h-screen pt-10">
        {/* Seção de Introdução */}
        <section className="p-8 flex flex-col items-center justify-center ">
          <div className="w-full lg:w-1/2 p-4">
            <img
              src={mints}
              alt="O que é o Mints"
              className="rounded-lg shadow-lg"
            />
          </div>
          {/* <p className="text-lg text-center max-w-4xl mb-6">
            O Mints é uma solução inovadora para o gerenciamento de oficinas
            mecânicas e certificação de veículos usados. Nossa plataforma
            oferece simplicidade, transparência e um ecossistema completo para
            atender tanto as oficinas quanto os clientes finais. Descubra como o
            Mints pode transformar sua oficina e o mercado de veículos usados.
          </p> */}
        </section>

        {/* Seção: O que é o Mints? */}
        <section className="flex flex-wrap items-center p-8 text-justify">
            <h2 className="text-3xl font-semibold mb-4">O que é o Mints?</h2>
            <p className="text-lg my-4">
              O Mints é uma solução inovadora criada após extensas pesquisas de
              campo, projetada para atender a grande demanda por oficinas
              mecânicas confiáveis e sistemas de gestão eficientes. Em um mundo
              onde a transparência e a confiança são fundamentais, o Mints surge
              como um sistema que prioriza a simplicidade e a eficácia,
              proporcionando uma experiência aprimorada tanto para os
              proprietários de veículos quanto para os profissionais das
              oficinas.
            </p>
            <p className="text-lg my-4">
              <strong>
                Nossa proposta é uma plataforma abrangente que integra diversas
                funcionalidades para otimizar a gestão de oficinas e a segurança
                na compra e venda de veículos.
              </strong>
              O Mints não apenas facilita o dia a dia das oficinas mecânicas,
              mas também garante que os consumidores tenham acesso a informações
              precisas e confiáveis sobre seus veículos.
            </p>
            <ul className="list-disc list-inside space-y-2">
              <li>
                <strong>Sistema de Gerenciamento de Oficinas:</strong> Uma
                ferramenta completa que permite o gerenciamento eficiente de
                todas as operações de oficinas mecânicas, desde agendamentos até
                a gestão de estoque.
              </li>
              <li>
                <strong>Avaliação e Certificação de Carros Usados:</strong>{" "}
                Processo rigoroso de certificação que assegura a integridade
                mecânica dos veículos, proporcionando segurança e confiança na
                compra.
              </li>
              <li>
                <strong>Oficinas Parceiras:</strong> Uma rede cuidadosamente
                selecionada de oficinas confiáveis e recomendadas, garantindo
                que nossos usuários tenham acesso aos melhores serviços
                disponíveis.
              </li>
              <li>
                <strong>Marketplace de Veículos Certificados:</strong> Um espaço
                seguro e eficiente para compra e venda de veículos, onde os
                usuários podem encontrar anúncios de veículos certificados,
                minimizando riscos e garantindo uma transação tranquila.
              </li>
            </ul>
        </section>

        {/* Seção: Pilares do Mints */}
        <section className="flex flex-wrap items-center  bg-gray-100 dark:bg-gray-800 p-8">
          
          <div className="w-full lg:w-1/2 p-4 text-center">
            {" "}
            {/* Adicionado text-center para centralizar */}
            <h2 className="text-3xl font-semibold mb-4">Pilares do Mints</h2>
            <ul className="list-disc list-inside space-y-2 text-justify">
              <li className="text-lg">
                <strong>Simplicidade:</strong> Um sistema intuitivo que prioriza
                a facilidade de uso.
              </li>
              <li className="text-lg">
                <strong>Transparência:</strong> Manter o processo de compra e
                venda de veículos claro e direto, garantindo que todos os
                envolvidos tenham acesso a informações precisas e completas.
              </li>
              <li className="text-lg">
                <strong>Satisfação:</strong> Nosso foco está em proporcionar a
                melhor experiência possível para o cliente, com suporte dedicado
                e soluções que atendam às suas necessidades.
              </li>
            </ul>
          </div>
          <div className="w-full lg:w-1/2 p-4">
            <img
              src={image1}
              alt="Pilares do Mints"
              className="rounded-lg shadow-lg"
            />
          </div>
        </section>

        {/* Seção: Nosso Mercado-Alvo */}
        <section className="flex flex-wrap items-centerp-8">
          <div className="w-full lg:w-1/2 p-4">
            <img
              src={image3}
              alt="Nosso Mercado-Alvo"
              className="rounded-lg shadow-lg"
            />
          </div>
          <div className="w-full lg:w-1/2 p-4">
            <h2 className="text-3xl font-semibold mb-4">Nosso Mercado-Alvo</h2>
            <p className="mb-4">
              Existem mais de 100.000 oficinas mecânicas no Brasil, a maioria de
              pequeno porte. O mercado de carros usados continua a crescer, com
              mais de 1,47 milhão de unidades registradas em agosto de 2024.
            </p>
            <p className="font-semibold mb-2">Principais estados:</p>
            <ul className="list-disc list-inside space-y-2">
              <li>São Paulo: 33.105 oficinas</li>
              <li>Minas Gerais: 15.417 oficinas</li>
              <li>Paraná: 12.351 oficinas</li>
            </ul>
            <p className="my-3 text-lg font-semibold">
              Em Volta Redonda e regiões próximas são mais de 120 oficinas,
              apenas no Google Maps.
            </p>
          </div>
        </section>

      

        {/* Seção: Modelo de Negócios */}
        <section className="flex flex-wrap items-center  bg-gray-100 dark:bg-gray-800 p-8">
          <div className="w-full lg:w-1/2 p-4">
            <img
              src={image4}
              alt="Modelo de Negócios"
              className="rounded-lg shadow-lg"
            />
          </div>
          <div className="w-full lg:w-1/2 p-4">
            <h2 className="text-3xl font-semibold mb-4">
              Modelo de Negócios e Receitas
            </h2>
            <p className="mb-4">
              <strong>Adesão de Oficinas:</strong> Oficinas pagam mensalidades
              para utilizar o sistema Mints.
            </p>
            <p className="mb-4">
              <strong>Avaliações de Veículos:</strong> O Mints recebe uma
              porcentagem de cada avaliação realizada.
            </p>
            <p className="mb-4">
              <strong>Expansão Futura:</strong> Modelo escalável, com potencial
              para novos serviços.
            </p>
          </div>
          <img
              src={valores}
              alt="Modelo de Negócios"
              className="rounded-lg shadow-lg"
            />
        </section>

        {/* Seção: Concorrência */}
        <section className="flex flex-wrap items-center  p-8">
          <div className="w-full lg:w-1/2 order-last lg:order-first p-4">
            <h2 className="text-3xl font-semibold mb-4">Concorrência</h2>
            <ul className="list-disc list-inside space-y-2">
              <li>
                <strong>Sistema Dignus:</strong> Funcionalidades desatualizadas
                e infraestrutura local.
              </li>
              <li>
                <strong>OnMotor:</strong> Versão paga necessária para
                funcionalidades mais avançadas.
              </li>
              <li>
                <strong>MinhaOficina.net:</strong> Funcional, mas precisa de
                infraestrutura local, não é SaaS.
              </li>
            </ul>
          </div>
          <div className="w-full lg:w-1/2 order-first lg:order-last p-4">
            <img
              src={concorrentes}
              alt="Concorrência"
              className="rounded-lg shadow-lg"
            />
          </div>
        </section>

          {/* Seção: Vantagens do Mints */}
          <section className="flex flex-wrap items-center bg-gray-100 dark:bg-gray-800 p-8">
          <div className="w-full lg:w-1/2 order-last lg:order-first p-4">
            <h2 className="text-3xl font-semibold mb-4">Vantagens do Mints</h2>
            <ul className="list-disc list-inside space-y-2">
              <li>Sistema SaaS acessível de qualquer dispositivo.</li>
              <li>
                Certificado Mints para garantir confiança na compra de veículos
                usados.
              </li>
              <li>Rede de oficinas confiáveis com avaliações dos clientes.</li>
            </ul>
          </div>
          <div className="w-full lg:w-1/2 order-first lg:order-last p-4">
            <img
              src={image4}
              alt="Soluções do Mints"
              className="rounded-lg shadow-lg"
            />
          </div>
        </section>

         {/* Seção: Diferencial Competitivo */}
         <section className="flex flex-wrap justify-center items-center p-8">
          <div className="w-full lg:w-1/2 order-last lg:order-first p-4">
            <h2 className="text-3xl font-semibold mb-4">
              Diferencial Competitivo
            </h2>
            <ul className="list-disc list-inside space-y-2">
              <li>
                <strong>Interface Simples:</strong> Fácil de usar.
              </li>
              <li>
                <strong>Ecossistema Completo:</strong> Atende tanto oficinas
                quanto clientes.
              </li>
              <li>
                <strong>Constante Evolução:</strong> Atualizações regulares para
                atender às demandas do mercado.
              </li>
            </ul>
          </div>
          
        </section>

        {/* Seção: Investimento e Parceiros */}
        <section className="flex flex-wrap items-center bg-gray-100 dark:bg-gray-800 p-8">
          <div className="w-full lg:w-1/2 p-4">
            <img
              src={image3}
              alt="Investimento e Parceiros"
              className="rounded-lg shadow-lg"
            />
          </div>
          <div className="w-full lg:w-1/2 p-4">
            <h2 className="text-3xl font-semibold mb-4">
              Investimento e Parceiros
            </h2>
            <ul className="list-disc list-inside space-y-2">
              <li>Busca por investidores alinhados com a visão do Mints.</li>
              <li>Necessidade de parceiros com expertise em marketing.</li>
              <li>
                Investimento inicial de R$ 400.000,00 para infraestrutura e
                marketing.
              </li>
            </ul>
          </div>
        </section>

       
      </div>
      <Footer />
    </>
  );
}

import React from "react";
import Banner from "../Banner";

import um from "../../assets/apps/unifoaGym/1.png";
import quatro from "../../assets/apps/unifoaGym/4.png";
import seis from "../../assets/apps/unifoaGym/6.png";

import GymCarousel from './GymCarousel';

export default function UniFoaGymBanner() {
  return (
    <Banner
      imageUrl="https://classpass-res.cloudinary.com/image/upload/f_auto/q_auto/bibo1shu1kn9rk5jgamd.jpg"
      title="UniFOA GYM."
      id="unifoagym"
    >
      <div className="my-10 ml-20 flex flex-col lg:flex-row lg:items-start">
        <div className="flex flex-col pr-6">
          <h2 className="text-2xl font-bold mb-4 ">
            UniFOA GYM.
          </h2>
          <p className="text-lg font-semibold mb-4 text-pretty">
            A proposta do UniFOA GYM é desenvolver um aplicativo mobile para a
            Academia FOA, substituindo as tradicionais fichas de papel por uma
            solução digital moderna. Um aplicativo desse tipo não só agrega valor
            à academia, mas também transmite uma imagem de inovação, eficiência e
            profissionalismo.
          </p>
          <div className="flex flex-wrap gap-4 mb-4">
            <img
              src={um}
              alt="Imagem 1"
              className="max-w-[80%] max-h-[600px] object-contain"
              style={{ width: "30%", height: "auto" }}
            />
            <img
              src={quatro}
              alt="Imagem 2"
              className="max-w-[80%] max-h-[600px] object-contain"
              style={{ width: "30%", height: "auto" }}
            />
            <img
              src={seis}
              alt="Imagem 3"
              className="max-w-[80%] max-h-[600px] object-contain"
              style={{ width: "30%", height: "auto" }}
            />
          </div>
          <div className="flex flex-col lg:flex-row lg:items-start my-10">
            <div className="lg:w-2/3 pr-6">
              <p className="text-lg leading-relaxed mb-4">
                O UniFOA GYM busca oferecer um pacote completo de tecnologia para a
                academia.
              </p>
              
              <p className="text-lg leading-relaxed my-4 text-justify">
                Inicialmente, o aplicativo substituirá as fichas de treino,
                que se tornaram obsoletas. Com ele, os professores poderão criar,
                atualizar e acompanhar as fichas de treino dos alunos pelo computador
                ou pelo próprio aplicativo. Isso facilitará o acompanhamento dos treinos
                e proporcionará uma experiência mais integrada e personalizada para os
                alunos, refletindo o compromisso da academia em oferecer um serviço de
                alta qualidade e alinhado às novas tecnologias.
              </p>

              <p className="text-lg leading-relaxed my-10">
                O acesso ao Aplicativo UniFOA GYM incluirá:
                <ul className="list-disc pl-5 mt-2">
                  <p className=" font-bold">Para os professores:</p>
                  <li>Criar e editar fichas de treino diretamente pelo aplicativo.</li>
                  <li>Montar treinos diretamente pelo celular.</li>
                  <li>Acompanhar o progresso de treinos dos alunos da academia.</li>
                  <li>Cadastrar alunos pelo aplicativo diretamente do celular.</li>

                  <p className="mt-2 font-bold">Para os Alunos:</p>
                  <li>Telas de login para alunos e tela de recuperação de senha</li>
                  <li>Tela de progresso do treino.</li>
                  <li>Tela de acompanhamento de exercício com imagens explicativas.</li> 
                  <li>Tela de estimativa de calorias de cada exercício.</li>
                  <li>Tela de ficha de treino informando repetições e cargas.</li>
                </ul>
              </p>

              <p className="text-base leading-relaxed mb-4">
                Os alunos terão telas próprias para visualizar suas fichas de treino e
                acompanhar em tempo real seu progresso. Além disso, serão incluídas
                imagens para auxiliar o aluno na execução do exercício. 
              </p>

              <p className="text-lg leading-relaxed mt-10 text-justify">
                <b>O UniFOA GYM também será uma ferramenta valiosa para os estagiários de educação física</b>, 
                que terão contato direto com as tecnologias modernas usadas no mercado de trabalho. 
                Ao se familiarizarem com o aplicativo, eles estarão mais preparados para atuar em academias e 
                outros espaços que adotam soluções digitais, ampliando suas competências e conhecimentos 
                tecnológicos.
              </p>

              <ul className="list-disc pl-5 mt-2">
                <p className="font-bold mt-10">Disponibilidade:</p>
                <li className="ml-10">App Android</li>
                <li className="ml-10">App iOS</li>
                <li className="ml-10">Gerenciador WEB</li>
              </ul>
              <p className="mx-2 mt-10 mb-2 font-extrabold">Prazo de desenvolvimento: 4 - 6 Meses.</p>
            </div>
            <div className="lg:w-1/3">
              <GymCarousel />
            </div>
          </div>
        </div>
      </div>
    </Banner>
  );
};
import React, { useEffect } from 'react';
import Header from '../components/Header';
import Footer from './../components/Footer';

function HomePage() {
  useEffect(() => {
    const darkMode = localStorage.getItem('darkMode') === 'true';
    if (darkMode) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, []);

  return (
    <>
      <Header />
      <div className="bg-white dark:bg-gray-900 text-gray-900 dark:text-gray-100 min-h-screen pt-16 flex flex-col items-center justify-center">
        <main className="text-center px-6 py-8 max-w-3xl mx-auto">
          <h1 className="text-4xl font-extrabold mb-6">Bem-vindo!</h1>
          <p className="text-lg mb-6">
             Site em construção.
          </p>
          <button 
            onClick={() => window.location.href = '/foa'}
            className="px-6 py-3 bg-blue-600 text-white rounded-full shadow-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300 my-3"
          >
            Clique aqui para ver as propostas de aplicações para o UniFOA/FOA!
          </button>
          <button 
            onClick={() => window.location.href = '/mints'}
            className="px-6 py-3 bg-green-600 text-white rounded-full shadow-lg hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 transition duration-300 my-3"
          >
            Clique aqui para saber mais sobre o Mints!
          </button>
        </main>
      </div>
      <Footer />
    </>
  );
}

export default HomePage;

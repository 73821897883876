import React, { useEffect, useState } from 'react';
import HeaderFoa from '../components/FoaPage/HeaderFoa';
import LabFoaBanner from './../components/FoaPage/LabFoaBanner';
import UniFoaGymBanner from '../components/FoaPage/UniFoaGymBanner';
import ClubeMaisBanner from '../components/FoaPage/ClubeMaisBanner';
import PoliclinicaBanner from '../components/FoaPage/PoliclinicaBanner';
import BooksBanner from '../components/FoaPage/BooksBanner';
import BlaBlaFoaBanner from '../components/FoaPage/BlaBlaFoaBanner';
import LinkedFoaBanner from '../components/FoaPage/LinkedFoaBanner';
import EventsBanner from '../components/FoaPage/EventsBanner';
import TccBanner from '../components/FoaPage/TccBanner';
import ScrollToTop from './../components/ScrollToTop';
import PageLoader from '../components/PageLoader';
import Intro from '../components/FoaPage/Intro';
import Footer from '../components/Footer';
import SacBanner from '../components/FoaPage/SacBanner';

function FoaPage() {
  const [isLoading, setIsLoading] = useState(true);
  const [isContentVisible, setIsContentVisible] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
      setIsContentVisible(true);
    }, 500);
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <PageLoader isLoading={isLoading} />
      <div className={`transition-opacity duration-500 ${isContentVisible ? 'opacity-100' : 'opacity-0'}`}>
        <HeaderFoa />
        <Intro />
        <LabFoaBanner />
        <UniFoaGymBanner />
        <ClubeMaisBanner />
        <BooksBanner />
        <BlaBlaFoaBanner />
        <LinkedFoaBanner />
        <EventsBanner />
        <TccBanner />
        <PoliclinicaBanner />
        <SacBanner />
        <ScrollToTop />
        <Footer />
      </div>
    </>
  );
}

export default FoaPage;
